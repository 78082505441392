export const applicationListColumns = {
  "ru": [
    {
      width: "60%",
      title: "Название приложения",
      dataIndex: "application_name",
    },
    {
      width: "40%",
      title: "Режим защиты",
      dataIndex: "protection_mode",
    },
  ],
  "en": [
    {
      width: "60%",
      title: "Application name",
      dataIndex: "application_name",
    },
    {
      width: "40%",
      title: "Protection mode",
      dataIndex: "protection_mode",
    },
  ],
};

export const mostAttackedApplicationColumns = {
  "ru": [
    {
      width: "70%",
      title: "Название приложения",
      dataIndex: "application_name",
    },
    {
      width: "30%",
      title: "Количество",
      dataIndex: "count",
    },
  ],
  "en": [
    {
      width: "70%",
      title: "Application name",
      dataIndex: "application_name",
    },
    {
      width: "30%",
      title: "Quantity",
      dataIndex: "count",
    },
  ],
};

export const incidentsCountInfoColumns = {
  "ru": [
    {
      title: "Общее число событий за период",
      dataIndex: "total_count",
    },
    {
      title: "Общее число заблокированных событий за период",
      dataIndex: "blocked_count",
    },
  ],
  "en": [
    {
      title: "Total events over the period",
      dataIndex: "total_count",
    },
    {
      title: "Total blocked events over the period",
      dataIndex: "blocked_count",
    },
  ],
};

export const topAttackTypesColumns = {
  "ru": [
    {
      title: "Тип атаки",
      dataIndex: "attack_type",
    },
    {
      title: "Описание",
      dataIndex: "description",
    },
    {
      title: "Количество",
      dataIndex: "count",
    },
  ],
  "en": [
    {
      title: "Attack type",
      dataIndex: "attack_type",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Quantity",
      dataIndex: "count",
    },
  ],
};

export const eventsCountBySeverityColumns = {
  "ru": [
    {
      title: "Уровень опасности события",
      dataIndex: "severity",
    },
    {
      title: "Количество",
      dataIndex: "count",
    },
  ],
  "en": [
    {
      title: "Severity",
      dataIndex: "severity",
    },
    {
      title: "Quantity",
      dataIndex: "count",
    },
  ],
};

export const topAttackOriginsByIPColumns = {
  "ru": [
    {
      title: "IP адрес клиента",
      dataIndex: "client_ip_address",
    },
    {
      title: "Количество",
      dataIndex: "count",
    },
  ],
  "en": [
    {
      title: "Client IP address",
      dataIndex: "client_ip_address",
    },
    {
      title: "Quantity",
      dataIndex: "count",
    },
  ],
};

export const topAttacksOriginsByGeoColumns = {
  "ru": [
    {
      title: "Название страны",
      dataIndex: "country_name",
    },
    {
      title: "Код страны",
      dataIndex: "country_code",
    },
    {
      title: "Количество",
      dataIndex: "count",
    },
  ],
  "en": [
    {
      title: "Country name",
      dataIndex: "country_name",
    },
    {
      title: "Country code",
      dataIndex: "country_code",
    },
    {
      title: "Quantity",
      dataIndex: "count",
    },
  ],
};

export const groupedBotAttacksColumns = {
  "ru": [
    {
      title: "Имя браузера клиента",
      dataIndex: "client_browser_name",
    },
    {
      title: "Версия браузера клиента",
      dataIndex: "client_browser_version",
    },
    {
      title: "Количество",
      dataIndex: "count",
    },
  ],
  "en": [
    {
      title: "Client browser name",
      dataIndex: "client_browser_name",
    },
    {
      title: "Client browser version",
      dataIndex: "client_browser_version",
    },
    {
      title: "Quantity",
      dataIndex: "count",
    },
  ],
};
