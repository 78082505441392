import * as Setting from "../Setting";

export function getCurrentConsent() {
  return fetch(`${Setting.ServerUrl}/api/get-current-consent`).then(res => res.json());
}

export function acceptConsent(consentId) {
  return fetch(`${Setting.ServerUrl}/api/accept-consent?consentId=${consentId}`, {
    method: "POST",
  });
}
