import * as Setting from "../Setting";

export function getReport(userUUID, startDate, endDate, language) {
  return fetch(`${Setting.ServerUrl}/api/get-report?userUUID=${userUUID}&startDate=${startDate}&endDate=${endDate}&language=${language}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Accept-Language": Setting.getAcceptLanguage(),
    },
  }).then(res => res.json());
}
