import React, {useEffect, useState} from "react";
import {Button, Modal, Spin} from "antd";
import * as ConsentBackend from "../backend/ConsentBackend";
import * as Setting from "../Setting";
import i18next from "i18next";

export const ConsentPage = ({history, logout}) => {
  const [consent, setConsent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);
  const [acceptanceLoading, setAcceptanceLoading] = useState(false);

  const fetchConsent = async() => {
    try {
      const res = await ConsentBackend.getCurrentConsent();
      if (res.status === "ok") {
        setConsent(res.data);
      } else {
        Setting.showMessage("error", res.msg);
      }
    } catch (e) {
      Setting.showMessage("error", e.message);
    } finally {
      setLoading(false);
    }
  };

  const acceptConsent = async() => {
    setAcceptanceLoading(true);
    try {
      const res = await ConsentBackend.acceptConsent(consent.id);
      if (res.status !== 200) {
        Setting.showMessage("error", res.msg);
      }
    } catch (e) {
      Setting.showMessage("error", e.message);
    } finally {
      setAcceptanceLoading(false);
    }
  };

  useEffect(() => {
    fetchConsent();
  }, []);

  const handleAccept = async() => {
    await acceptConsent();
    setVisible(false);
    history.push("/");
  };

  const handleReject = () => {
    setVisible(false);
    logout();
  };

  return (
    <Modal
      title={i18next.t("consent:Сonsent to the processing of personal data")}
      visible={visible}
      footer={[
        <Button key="reject" onClick={handleReject}>
          {i18next.t("consent:Refuse")}
        </Button>,
        <Button key="accept" type="primary" onClick={handleAccept} loading={acceptanceLoading}>
          {i18next.t("consent:Agree")}
        </Button>,
      ]}
      closable={false}
    >
      {loading ? (
        <Spin />
      ) : (
        consent && (
          <div>
            <p style={{display: "inline"}}>
              {i18next.t("consent:We inform you that the Site contains a")}&nbsp;
            </p>
            <a style={{display: "inline"}} href={consent.documentLink} target="_blanck">
              {i18next.t("consent:Privacy Policy and Personal Data Processing.")}&nbsp;
            </a>
            <p style={{display: "inline"}}>
              {i18next.t("consent:By continuing to use the Site, the User expresses his specific and unequivocal agreement with all the terms of this policy.")}
            </p>
          </div>
        )
      )}
    </Modal>
  );
};
